import password from 'lib/validators/password'
import passwordConfirmation from 'lib/validators/passwordConfirmation'
import required from 'lib/validators/required'

export default model => ({
  current: [
    required,
    password,
  ],
  password: [
    required,
    password,
  ],
  password_confirmation: [
    required,
    passwordConfirmation(model.value.password),
  ],
})
