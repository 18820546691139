import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default (password: string) => ({
  validator (rule: any, value: string, callback: CallbackFunction) {
    if (value.length < 8) {
      return callback(new Error(i18n.tc('validator.shortPassword')))
    }
    if (value !== password) {
      return callback(new Error(i18n.tc('validator.matchPassword')))
    }
    callback()
  },
  trigger: [Trigger.Blur, Trigger.Change],
})
