import {
  CallbackFunction,
  Trigger,
} from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator (rule: any, value: any, callback: CallbackFunction) {
    if (value.length < 8) {
      return callback(new Error(i18n.tc('validator.shortPassword')))
    }
    callback()
  },
  trigger: [Trigger.Change, Trigger.Blur],
}
