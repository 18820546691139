import email from 'lib/validators/email'
import required from 'lib/validators/required'

export default function validationRules (model) {
  return {
    name: [
      required,
    ],
    company: [
      required,
    ],
    email: [
      required,
      email,
    ],
  }
}
