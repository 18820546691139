<template>
  <ElForm
    class="partner-settings-form"
    ref="form"
    :model="partner"
    :rules="formValidationRules"
    @submit.prevent.native="onSubmit"
  >
    <ElFormItem
      prop="name"
      :label="$t('placeholder.name')"
    >
      <ElInput
        :placeholder="$t('placeholder.name')"
        v-model="partner.name"
      />
    </ElFormItem>
    <ElFormItem
      prop="company"
      :label="$t('placeholder.companyName')"
    >
      <ElInput
        :placeholder="$t('placeholder.companyName')"
        v-model="partner.company"
      />
    </ElFormItem>
    <ElFormItem
      prop="email"
      :label="$t('placeholder.email')"
    >
      <ElInput
        :placeholder="$t('placeholder.email')"
        v-model="partner.email"
      />
    </ElFormItem>

    <ElFormItem
      prop="currency"
      :label="$t('placeholder.commonCurrency')"
    >
      <ElSelect
        :placeholder="$t('placeholder.commonCurrency')"
        v-model="partner.currency"
        disabled
      >
        <ElOption
          v-for="item in currencies"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </ElSelect>
    </ElFormItem>

    <div class="partner-settings-form__buttons">
      <ElButton
        type="primary"
        native-type="submit"
        :disabled="false"
      >
        {{ submitButtonText }}
      </ElButton>
    </div>
  </ElForm>
</template>

<script setup>
import ElButton from 'element/ElButton'
import ElForm from 'element/ElForm'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'
import { computed, ref } from 'vue'

import { vm } from '@/main'

import validationRules from './validationRules'

const form = ref(null)

const partner = computed(() => vm.$store.getters['auth/partner'])
const currencies = computed(() => vm.$store.getters['dictionaries/currencies'])
const formValidationRules = computed(() => validationRules(partner))
const submitButtonText = computed(() => {
  if (partner.value.pending) {
    return vm.$t('button.saving')
  }
  return vm.$t('button.save')
})

const onSubmit = () => {
  form.value.validate(async status => {
    if (status) {
      await partner.value.update()
      await partner.value.fetch()
    }
  })
}
</script>

<style lang="scss" scoped>
.partner-settings-form {
  width: 460px;

  ::v-deep {
    .el-form-item__label:before {
      display: none;
    }

    .el-button {
      width: 220px;
      text-transform: none;
      margin-top: 16px;
    }
  }
}
</style>
